
:root{
    --BottomMargin: 15px;
}

.lineAcross{
    border-bottom: 1px solid;
    width: 100%;
    margin-bottom: 10px;
}

.label{
    font-size: 13px;
    font-style: italic;
    margin-bottom: 0px;
}

.studentFileFileName{
    background-color: var(--colorBackground);
    border-radius: var(--borderRadius);
    box-shadow: var(--boxShadow);
    padding: 12px 32px;
    margin-bottom: var(--BottomMargin);
    font-size: 20px;
}

.studentFileClasses{
    background-color: var(--colorBackground);
    border-radius: var(--borderRadius);
    box-shadow: var(--boxShadow);
    min-width: fit-content;
    margin-bottom: var(--BottomMargin);
}

.studentFileAddClass{
    color: #474f7a;
    /* background-color: red; */
    margin-left: 10px;
    /* background-color: rgb(255, 229, 229); */
    position: relative;
    font-size: 30px;
    top: 0px;
    left: 0px;
    /* color: rgb(12, 212, 129); */
}
.studentFileAddClass:hover{
    color: #6f4d6f;
}


.studentFileTitle{
    font-size: 20px;
    padding-bottom: 20px;
}

.studentFileClassCard{
    border-radius: var(--borderRadius);
    box-shadow: var(--boxShadow);
    background-color: #c4d4dc;
    color: rgb(71, 71, 71);
    margin: 10px 10px;
    padding: 10px 10px;
    height: 300px;
    width:100%;
    max-width: 320px;
}

.studentFileClinicalCard {
  height: auto !important
}

.modalForMobile{
    max-width:80vw;
    margin-left: 8vw;
}

.studentFileClassesCardsArea{
    /* background-color: rgb(253, 239, 239); */
    min-width: fit-content;
    display: flex;
    flex-flow: row wrap;
}


/* For edit buttons
https://alioguzhan.github.io/react-editext/ */

button[editext='edit-button']{
    background-color: transparent;
    border: none;
    color: rgb(0, 0, 0);
    }
    button[editext='edit-button']:hover{
        background-color: transparent;
    }


button[editext="save-button"]:hover {
    background-color: #56825d;
    color: white;
}


button[editext="cancel-button"]:hover {
    background: crimson;
    color: #fff;
}

input, textarea {
background: #eef0f3;
border-radius: var(--borderRadius);
}

div[editext="view-container"]{
    margin-left: 10px;
    font-size: 17px;
    word-wrap: break-word;
    width: 100%;
    max-width: 100%;
    height: fit-content;
}
div[editext="edit-container"]{
    /* height: 15px; */
}


.cardClinicalType{
    /* background-color: red; */
    margin: 10px;
    font-weight: bold;
    font-size: 17px;
    word-wrap: break-word;
    max-width: '80%';
    margin-left: 10px
    
}

.cardClinicalData{
    /* background-color: red; */
    margin: 10px;
    font-size: 17px;
    word-wrap: break-word;
    max-width: '80%';
    margin-left: 10px
    
}

.timeInput{
    width: 65%;
    float: right;
}

.timeTextReminder{
    margin-left: 0px;
    margin-top: 80px;
}

.studentFileAddClassInput{
    margin-bottom: 10px;
    background-color: green;
    border: 1px solid rgb(197, 197, 197);
    background-color: var(--colorBackground);
    border-radius: var(--borderRadius);
    box-shadow: var(--boxShadow);
}

.modal{
    min-width: 600px;
}

.addClassModalBody{
    /* margin-top: 30px !important; */
    margin: auto;
    
    /* width: 60%; */
    max-width: 500px;

    /* min-width: 300px; */
}

.addClassModalBodyMobile{
    margin: auto;
    width: 100%;
}

.errorMessage{
    margin: auto;
    text-align: center;
    color: red;
    height: 20px;
}


.addClassWeekDayButtons{
    margin-bottom: 20px;
    padding: 0 4px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.addClassWeekTrue{
    cursor: pointer;
    width: 60px;
    border-radius: 5px !important;
    box-shadow: var(--boxShadowWhiteBackground);
    border: none;
    background-color: #474f7a;
    color: white;
    border: 1px solid rgb(197, 197, 197);
    padding:0;
}
.addClassWeekFalse{
    cursor: pointer;
    width: 60px;
    border-radius: 5px !important;
    box-shadow: var(--boxShadowWhiteBackground);
    background-color: var(--colorBackground);
    border: none;
    border: 1px solid rgb(197, 197, 197);
    padding:0;
}

.studentFileCardClassName{
    /* background-color: orange; */
    font-weight: bold;
    word-wrap: break-word;
    margin-bottom: 4px;
}

.studentFileCardInfo{
    max-width: 88%;
    word-wrap: break-word;
    margin: 5px 0px 20px 15px;
}

.studentFileCardReason{
    width: 101% !important;
    border: none !important;
    height: 80px !important;
    background-color: #c4d4dc;
    scroll-margin-right: 5px;
    font-size: 14px;
    resize: none;
}

.studentCardIcon{
    width: 35px;
    display: block;
    /* margin-left: 5px;
    margin-right: 5px; */
    padding: 3px 5px 3px 4px;
    border-radius: var(--border-radius);
    float: right;
}
.studentCardIcon:hover{
    color: white;
    background-color: #474f7a;
    border-radius: var(--borderRadius);

    transition: background-color .3s, color .1s;   
}

.studentCardIconSituation{
    /* background-color: red; */
    width: 40px;
    /* height: 20px; */
    padding: 3px;
    font-size: 20px;
    border-radius: var(--border-radius);
    margin-top: -15px;
    /* float: right; */
}
.studentCardIconSituation:hover{
    color: white;
    background-color: #474f7a;
    border-radius: var(--borderRadius);

    transition: background-color .3s, color .1s;   
}


.studentFileEmptyNotice{
    color: rgba(78, 78, 78, 0.808);
}

.studentFileClinicalTypeButton{
    margin-right: 10px;
    padding: 2px 20px;
    height: 35px;
    top: 0px;
    margin-top:10px ;
    font-size: 18px;
    border: none;
    box-shadow: var(--boxShadow);
}

.studentFileClinicalTypeSelected{
    border-radius: 5px !important;
    color: white;
    background-color: #24435f;
}

.studentFileClinicalTypeNotSelected{
    border-radius: 5px !important;
    background-color: #e2e2e2;
    box-shadow: none !important;
}


.hover-underline-animation {
    display: inline-block;
    position: relative;
}
  
.hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 80%;
    transform: scaleX(0);
    height: 2px;
    bottom: 5px;
    left: 10px;
    background-color: #94adb9;
    border-radius: var(--borderRadius);
    transform-origin: bottom middle;
    transition: transform 0.25s ease-out;   
}

.hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom middle;
}

.editIconStudentFile{
    background-color: transparent;
    width: 40px;
    /* margin: 16px; */
    /* float: left; */
    /* display: flex;
    align-items: left;
    justify-content: left; */
    /* background-color: red; */
    padding: 0px 5px;
    
}

.editIconStudentFile:hover {

    /* Turns icon green. Same green used on the check mark for the student list */
    filter: invert(28%) sepia(99%) saturate(1318%) hue-rotate(93deg) brightness(90%) contrast(106%);
}