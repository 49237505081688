.flex-container{
    display: flex !important;
    flex-wrap: wrap !important;
    /* flex-direction: column; */
    justify-content: space-around !important;
    /* align-items: center !important; */
    width: 100%;
    height:300px;
    /* background-color: #24435F; */
    background-color: #364A5E;
}
.sub-container{
    display: flex !important;
    flex-wrap: wrap !important;
}
.clinicalAssistant{
    font-family: 'Glac';
    font-weight: 'Regular';
    color:#f7f7f7;
    font-size: 35px;
    margin-left:1px;
    margin-right:10px;
    line-height: 85%;
    text-align: left !important;
}
.textBodyContainer{
    margin-right:20px;
}
.textHead{
    font-family: 'Glac';
    font-weight: 900;
    color:#f7f7f7;
    font-size: 20px;
}
.textBody{
    font-family: 'Glac';
    font-weight: 'Regular';
    color:#f7f7f7;
    font-size: 18px;
}
.homepageFooterLink {
  cursor: pointer;
  background-color: transparent !important;
}
.copiedToClipboardMessage{
  background-color: Green;
  border-radius: 5px;
}
.textBodyEmail{
    font-family: 'Glac';
    font-weight: 'Regular';
    color:#f7f7f7;
    font-size: 18px;
}
.textBody:hover{
    font-family: 'Glac';
    font-weight: 'Regular';
    color:#f1f1f14b;
    font-size: 18px;
}