.body{
    border-radius: 10px;  
    text-align: center;
    margin: 1.5rem auto;
    margin-top: 100px;
    width: fit-content; 
    background-color: #ffffff; 
    max-width: min-content;
    box-shadow:var(--boxShadow);
}

.title{
    font-size:2em;
    padding-top: 30px;
}
/* .loginInput{
    background-color: red;
} */
.form{
    align-self: center;
    display: inline-block;
    margin: 0 auto;
    padding: 30px;
}

.error{
    font-size: 18px;
    color: rgb(241, 241, 241);
    background-color: rgb(230, 78, 78);
    overflow-wrap: break-word;
    width: 100%;
    height: fit-content;
    padding: 5px 20px;
    border-radius: 2px;    
}