.Appoint_Modal_Small {
  min-width: 500px !important;
  max-width: 98vw;
}

.Appoint_Modal_Medium {
  min-width: 800px !important;
  max-width: 98vw;
}

.Appoint_Modal_Large {
  min-width: 1000px !important;
  max-width: 98vw;
}