.search{
    background-color: transparent ;
    width: 100%;
    height: 95%;
}

.searchInputs {
    /* width: 200px; */
    padding: 0 20px;
    background-color: transparent;
    margin-top: 10px;
    display: flex;
    /* border-bottom: 1px solid black; */
  }

.studentSearchButton{
    color: #24435f;
}

.tempCircumstance{
    color: red;
    /* font-weight: bold; */
}

.SearchBarStudentBackground{
    background-color: rgb(230, 230, 230) !important;
    color: white;
    /* font-weight: bold; */
}


.studentSearchFileterArea{
    /* background-color: rgb(247, 138, 138); */
    height: 30px;
    width: 105%;
    display:table;
}

.studentSearchInnerIcons{
    width: 100%;
    margin-right: 12px;
    display:table-row;
    /* width: fit-content; */
    float: right;
}

.searchBarFilterTrue{
    color: white !important;
    background-color: #24435f !important;
}

.strikethrough {
    position: relative;
  }

.strikethrough:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: inherit;
    color:red !important;
    font-weight: bold;
    
    -webkit-transform:rotate(20deg);
    -moz-transform:rotate(45deg);
    -ms-transform:rotate(45deg);
    -o-transform:rotate(45deg);
    transform:rotate(45deg);
  }
.studentSearchInnerButton{
    margin-left: 8px;
    border-radius: 5px !important;
    width: 80px;
    display:table-cell;
    box-shadow: 0 0 3px 1px rgb(235, 235, 235);
    font-size: 13px;
    background-color: #e2e2e2;
    border: none;
}

.tempCheckMark{
    color: green;
}
  
.search input {
    background-color: transparent;
    border: 0;
    border-radius: 2px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    /* font-size: 18px; */
    padding: 15px;
    height: 30px;
}

.searchIcon {
    height: 40px;
    width: 30px;
    padding-right: 20px;
    background-color: transparent;
    display: grid;
    place-items: center;
    }

input:focus {
    outline: none;  
}
.searchIcon svg {
    font-size: 25px;
}
.ul{
    list-style-type: none;
}

  
.dataResult {
    /* margin-top: 15px; */
    /* z-index: 1; */
    background-color: transparent;
    list-style: none outside none;
    /* margin-top: 20px; */
    position: relative;
    /* width: 100%; */
    height: calc(45vh - 70px);
    overflow: auto;
    /* overflow-y: scroll; */
    /* -webkit-mask-image: linear-gradient(to bottom, black 65%, transparent 90%); */
    /* -webkit-mask-image: linear-gradient(bottom, black); */
    /* mask-image: linear-gradient(to bottom, black 70%, transparent 90%); */
}
.dataResult:after {
    content: '';
    position: sticky;
    display: block;
    bottom: 0;
    width: 100%;
    height: 55px;
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0.849) 45%, transparent 90%);
  }
.dataResult::-webkit-scrollbar {
    /* display: none; */
    z-index: 2;
}

.dataResult::-webkit-scrollbar-thumb{
    z-index: 2;
}

.dataResult .dataItem {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    color: black;
    /* background-color: red; */
}

.dataItem p {
    margin-left: 10px;
    padding-top: 15px;
    margin-left: 10px;
    /* this padding is half of the height that is aboves */

}
 
.dataItemStyling{
   padding-left: 10px;
   padding-top: 6px;
   padding-right: 10px;
   text-align: left;
   width: 100%
   
}

.StudentSearchBarStudentName{
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 190px;    
}

a {
    text-decoration: none;
}

a:hover {
    background-color: lightgrey;
}

#clearBtn {
    cursor: pointer;
}

#bottom_fade {
	width: 300px;
	height: 20px;
    z-index: 5;
	/* position: relative; */
	bottom: 10%;
	background-image: url("bottom-fade.png");
    /* transform: translate(-50%, -50%); */
}

.SearchBar_StudentFullyScheduled{
    color:rgb(17, 146, 0) !important;
}