.ContactUs_Body{
    border-radius: 10px;  
    text-align: center;
    margin: 1.5rem auto;
    margin-top: 50px;
    margin-bottom: 50px;
    padding-top: 10px;
    width: 700px; 
    background-color: #ffffff; 
    box-shadow:var(--boxShadow);
}

.ContactUs_BodyMobile{
    width: 90%;
}

.ourTeam_AppointLogo{
    max-width: min(400px, 93vw);
    margin-top: -15px;
    margin-left: -5px;
}

.OurTeam_Title{
    margin: 0px;
    /* margin-top: 100px; */
    font-size: 72px;
    font-weight: bold;
    color: white;
    padding-top: 50px;
    align-items: center;
    align-self: center;
    font-family: Glac;
}

.OurTeam_SubTitle{
    margin: 0px;
    margin-bottom: -100px;
    font-size: 34px;
    font-weight: bold;
    align-items: center;
    align-self: center;
    color: white;
    font-family: Glac;
}

.OurTeam_Container{
    margin-bottom: 25px;
    margin-top: 150px;
    display: flex !important;
    flex-direction: row;
    height: 315px;
    width: 100% !important;
    border: none;
    background-color: #a0b9c6;
    padding-bottom: 15px;
}

.OurTeam_ContainerMobile{
    margin-bottom: 25px;
    margin-top: 150px;
    display: flex !important;
    flex-direction: column;
    height: 2000px;

    width: 75% !important;
    /* width: 85% !important;    
    padding-left: 5%;
    padding-right: 5%; */

    border: none;
    /* background-image: linear-gradient(to bottom, #a0b9c6 0, #a0b9c6 280px, transparent 280px, transparent 465px, 
    #a0b9c6 465px, #a0b9c6 745px,  transparent 745px, transparent 925px,  #a0b9c6 925px, #a0b9c6 1205px, 
    transparent 1205px, transparent 1390px, #a0b9c6 1390px, #a0b9c6 1670px, transparent 1670px, transparent 1855px,
    #a0b9c6 1855px, #a0b9c6 2135px, transparent 2135px); */
}

/* .OurTeam_headshots{
    margin-top: -220px;
    height: 200px;
    width: auto;
    border: none; */

    /* background-size: cover;
    background-position: center;
    background-repeat:no-repeat; */
        
/* } */

.fixMargin{
    margin-left:-20px;
}

.OurTeam_KeyPointsButton{
    min-height: 180px;
    min-width: 100%;
    max-width: 150px;
    
    margin: 15px;

    margin-bottom:0px;
    padding: 20px;
    border-radius: var(--borderRadius);
    background-color: white;
    box-shadow: var(--boxShadow);
    border: none;
    font-family: Glac;
}

.OurTeam_KeyPointsButtonName{
    font-size: 20px;
    font-weight: bold;
    margin-bottom:-8px;
    margin-top: -20px;
    font-family: Glac;
}

.OurTeam_KeyPointsButtonTitle{
    font-size: 16px;
    margin-bottom:8px;
    font-family: Glac;
}

.OurTeam_KeyPointsButtonText{
    height: 70px;
    font-family: Glac;
}

.Home_OurGoal{
    background-color: white;
    padding-bottom: 100px;
}

.Home_OurGoalTitle{
    font-size: 72px;
    padding-top: 50px;
    font-weight: bold;
    color: white;
    margin: 0;
}

.Home_OurGoalSubTitle{
    font-size: 30px;
    font-weight: bold;
    color: white;
    margin-top: -10px;
    padding-left: 15px;
    padding-right: 15px;
}

.Home_OurGoalText{
    font-size: 19px;
    color: white;
    padding-left: 15px;
    padding-right: 15px;
}


/* .body{
    border-radius: 10px;  
    text-align: center;
    margin: 1.5rem auto;
    margin-top: 100px;
    width: fit-content; 
    background-color: #ffffff; 
    max-width: min-content;
    box-shadow:var(--boxShadow);
}

.title{
    font-size:2em;
    padding-top: 30px;
}

.form{
    align-self: center;
    display: inline-block;
    margin: 0 auto;
    padding: 30px;
}

.error{
    font-size: 18px;
    color: rgb(241, 241, 241);
    background-color: rgb(230, 78, 78);
    overflow-wrap: break-word;
    width: 100%;
    height: fit-content;
    padding: 5px 20px;
    border-radius: 2px;    
} */


.HomePage_OurTeam_Main {
    text-align: center;
  }
  
  .HomePage_OurTeam_Main-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .HomePage_OurTeam_Main-logo {
      animation: HomePage_OurTeam_Main-logo-spin infinite 20s linear;
    }
  }
  
  .HomePage_OurTeam_Main-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .HomePage_OurTeam_Main-link {
    color: #61dafb;
  }
  
  @keyframes HomePage_OurTeam_Main-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  