
.userProfile{
    padding: 4vh;
    background-color: var(--colorBackground);
    border-radius: var(--borderRadius);
    box-shadow: var(--boxShadow);
}

.userProfilePictureSection{
    width: fit-content;
    display: flex-wrap;
    width: 100%;
    
}
.Mobile_userProfilePictureSection{
    width: fit-content;
    display: flex-wrap;
    width: 100%;
    margin-left: 15px;
}

.userProfilePicture{
    /* background-color: red; */
    box-shadow: var(--boxShadow);
    height: 200px;
    border-radius: var(--borderRadius);
    font-size: 54px;
    
    
}
.userProfileNameAddress{
    /* padding-left: 50px; */
    /* background-color: rgb(255, 212, 255); */
    width: 100%;
    height: fit-content;
}

.userProfileName{
    font-size: 25px;
}

.userProfileYear{
    color: #474f7a;
    font-weight:bold;
}

.userProfileAddress{
    padding-left: 10px;
    flex:none;
}

.userProfileAddressPin{
    height: 20px;
    margin-left: 2px;
}

/* .userProfileYearInfo{
    padding-left: 50px;
} */

.userProfileViewSelector{
    margin-top: 50px;
}

.userProfileButton{
    border-radius: 0 !important;
    border: none;
    width: 120px;
    background-color: transparent;
}
.userProfileButton:hover{
    background-color: #f7f7f7; 
}

.userProfileMainContent{
    padding: 20px;
    margin-top: .2px;
    border-top: 1px solid rgb(221, 221, 221);
}

.userProfileAboutSection{
    display: flex;
    /* background-color: red; */
}

.userProfileTitle{
    border-bottom: 1px solid rgb(221, 221, 221);
    margin-bottom: 10px;   
}

.userProfileBasicInformation{
    margin-right: 40px;
    width: 30%;

}

.userProfileContactInformation{
    width: 30%;
    /* background-color: red; */
}

.userProfileInformationCard{
    width: 100%;
}

.userProfileInformationDescription{
    margin-bottom: 7px;
    float: left;
    /* background-color: rgb(255, 213, 213); */
    width: 50%;
}

.userProfileInformation{
    margin-bottom: 7px;
    text-align: right;
    float: right;
    /* background-color: rgb(255, 230, 185); */
    width: 50%;
}

.mobile_userProfileInformation{
    margin-bottom: 7px;
    text-align: right;
    float: right;
}

.mobile_emailText{
    word-break: break-all;
    width: 70%;
    float: right;
    margin-left: auto;
    text-align: right;
}

.mobile_rows{
    display: flex;
}

.mobile_Columns{
    flex: 50%;
}

.deletebutton {
    width: 200px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    background: red;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.15);
    background: #e62222;
}

/* .deletebuttongreen {
    background-color: ;
} */

.deletebutton, .deletebutton span {
    transition: 200ms;
}

.deletebutton .text {
    transform: translateX(30px);
    color: white;
    font-weight: bold;
}

.deletebutton .icon {
    position: absolute;
    border-left: 1px solid #981616;
    transform: translateX(155px);
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.deletebutton svg {
    width: 15px;
    fill: #eee;
}

.deletebutton:hover {
    background: #ff3636;
}

.deletebutton:hover .text {
    color: transparent;
}

.deletebutton:hover .icon {
    width: 200px;
    border-left: none;
    transform: translateX(0);
}

.deletebutton:focus {
    outline: none;
}

.deletebutton:active .icon svg {
    transform: scale(0.8);
}


.deletebuttongreen {
    /* margin-left: 40px; */
    width: 200px;
    height: 40px;
    cursor: pointer;
    display: flex !important;
    /* justify-content: center;
    align-items: center;
    margin: auto;
    text-align: center; */
    /* float: left; */
    align-items: center !important;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 3px rgba(0,0,0,0.15);
    background: green;
}

/* .deletebuttongreengreen {
    background-color: ;
} */

.deletebuttongreen, .deletebuttongreen span {
    transition: 200ms;
}

.deletebuttongreen .text {
    transform: translateX(30px);
    color: white;
    font-weight: bold;
}

.deletebuttongreen .icon {
    position: absolute;
    border-left: 1px solid rgb(2, 82, 2);
    transform: translateX(155px);
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.deletebuttongreen svg {
    margin-top: 5px;
    margin-left: 10px;
    width: 50px;
    fill: #eee;
}

.deletebuttongreen:hover {
    background: green;
}

.deletebuttongreen:hover .text {
    color: transparent;
}

.deletebuttongreen:hover .icon {
    width: 200px;
    border-left: none;
    transform: translateX(0);
}

.deletebuttongreen:focus {
    outline: none;
}

.deletebuttongreen:active .icon svg {
    transform: scale(0.8);
}

.viewTOSbutton{
  padding: 10px;
  border: none;
  background-color: gray;
  border: gray;
  color: white;
  font-size: 15px;
  border-radius: var(--borderRadius);
}

.viewTOSbutton.active{
    padding: 10px;
    border: none;
    background-color: white;
    border: gray;
    color: white;
    font-size: 15px;
    border-radius: var(--borderRadius);
  }