/* Below are the different css styles for the features on the homepage.  */
/* You can use a combination of setting both a value and a max value, such as width and max-width (or height), in the same style */
/* The effect of this is that whichever is smaller will be displayed. Same with min-width, then whichever is larger will display */
/* Lastly, vw stands for view-width, and vh for view-height. This is percentage of pixels. Allows for expansion on larger screens. */
/* You will see combinations of the above, such as pairing a #px height with a #vh max-height */

.home-flex-container{
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: space-around !important;
    align-items: center !important;

    /* vertical spacing between rows of features. Negative pushes into one another */
    margin-bottom: -120px;
}

.homeFeatures_row_imageRight{
    /* for the rows with an imge on the right. Can adjust spacing pushing in from either side */
    margin-left: 120px;
    margin-right: 120px;
}

.homeFeatures_mobile_margins{
    margin-left: 15px !important;
    margin-right: 15px !important; 
    width:90% !important;
}

.homeFeatures_row_imageLeft{
    /* for the rows with an imge on the right. Can adjust spacing pushing in from either side */
    margin-left: 120px;
    margin-right: 120px;
}

.HomeFeatureText{
    /* text boxes sizing.  */
    width: 30vw;
    max-width: 665px;
    text-align: left !important;
}

.HomeFeatureTextMobile{
    /* text boxes sizing.  */
    width:80% !important;
    text-align: left !important;
}

.FeatureImage{
    width: 500px;
    /* background-color: blue; */
}







.HomePage_MainDiv{
    font-family: Glac;
    scroll-behavior: smooth;
    width: 100vw !important;
}

.home_logoSpinner {
    position: fixed;
    bottom: 10px;
    right: 0;
    height: 150px;
    width: 150px;

    z-index: -1;
}

.HomeHeaderContainer{
    /* background-color: rgb(255, 227, 227); */
    background-color: white;
    padding-top: 20px;
    padding-left: 15%;
    padding-right: 15%;
    max-width: 100%;
    /* padding-top: 30px; */
    margin-top: 0px;
    padding-bottom: 30px;

}

.HomeHeader{
    display: flex;
    flex-wrap: wrap;
    flex-direction:row-reverse;
    width: 100%;
    /* background-color: rgb(237, 100, 237); */
}

.HomeMotto{
    font-size: 40px;
    margin-top: 50px;
    width: 300px;
    position: relative;
    /* background-color: rgb(184, 184, 255); */
}

.HomeHeaderLeft{
    /* background-color: burlywood; */
    width: fit-content;
    margin: auto;
}

.HomeHeaderSentence{
    font-size: 18px;
    margin-top: 30px;
    width: 400px;
    /* background-color: rgb(184, 184, 255); */
}

.HomeHeader2{
    background-color: red;
    width: 100%;
    background-color: black;
}
.HomePageHeaderImage{
    background-position: 100% 100%;
    filter: brightness(40%) !important;
    background-size: cover;
    height: 100%;
    z-index: -1;
    position: absolute;
}

.AboutSectionContainer{
    height: 2000px;
    padding: 20px 150px 0px 150px;

}
.AboutSectionContainer{
    /* background-position: 100% 100%; */
    /* filter: brightness(40%) !important; */
    background-image: url(../../../public/HomePageBackgroundOne.png);
    background-position: 100%;
    filter: brightness(102%);
    background-size: cover;
    width: 100vw;
    /* height: 100px; */
    position: abolute;
    z-index: 0;
}
.HomeTitle{
    --bs-text-opacity: 1;
    color: rgba(33,37,41,var(--bs-text-opacity))!important;
    color: rgba(var(--bs-dark-rgb),var(--bs-text-opacity))!important;
    text-transform: uppercase!important;
    font-weight: 300!important;
}
.SectionTitleUnderline{
    border-radius: 50px;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
}
.SectionTitleUnderline:after, .SectionTitleUnderline:before {
    border-radius: 50px;
    content: "";
    left: 150px;
    position: absolute;
    right: 0;
}

.SectionTitleUnderline:before {
    background-color: #24435F;
    height: 2px;
    top: 10px;
    width: 200px;
}
.SectionTitleUnderline:after {
    background-color: #ff8700;
    height: 6px;
    top: 8px;
    width: 30px;
}
/* *, :after, :before {
    box-sizing: border-box;
} */

/* Needed for resizing purposes to make sure background remains grey*/
.home_KeyPointsContainer{
    background: transparent;
}

/* Width is set dynamically in the Home.js file */
.home_KeyPoints{
    /* justify-content: space-around !important; */
    padding: 20px;
    /* width: 500px; */
    /* padding-top: 30px; */
    /* display: flex !important; */
    display: block !important;
    /* flex-wrap: wrap; */
    flex-direction: row;
    
}

.home_KeyPointsButton{
    height: 250px;
    width: 100%;
    min-width: 250px;
    margin: 20px;
    padding: 20px;
    border-radius: var(--borderRadius);
    background-color: white;
    box-shadow: var(--boxShadow);
    border: none;
    
}

.home_KeyPointsButton:hover{
    background-color: rgb(248, 248, 248);
}

.home_FeaturesGlanceTitle{
    font-size: 30px;
    margin-top: 50px;
}

.home_FeaturesGlanceText{
    max-width: 700px;
    padding-bottom: 30px;
    padding-Left: 15px;  /* for mobile, but no effect on desktop site since length of text displayed */
    padding-Right: 15px; /* for mobile, but no effect on desktop site since length of text displayed */
}

.HomeFeatureTitle{
    font-size: 25px;
    text-align: left !important;
}

.HomeFeatureTitleMobile{
    font-size: 25px;
    text-align: center !important;
}

.HomeMissionStatement{
    background-color: #f7f7f7;
    height: 500px;
}

.HomeMissionStatementTitle{
    font-size: 40px;
    padding-top: 50px;
}

.HomeContactUs{
    background-color: #f7f7f7;
    height: 500px;
    width: 100vw;
    padding-bottom: 600px;
}

.HomeContactUsTitle{
    font-size: 40px;
    padding-top: 50px;
}


/* Color has to be controlled inLine */
.home-FeatureButtonIcon{
    font-size: 35px;
    margin-bottom: 20px;
}

.home_KeyPointsButtonTitle{
    /* background-color: orange; */
    font-size: 20px;
    margin-bottom:8px;
    /* font-weight:550; */
}

.home_KeyPointsButtonText{
    color: grey;
    /* background-color: red; */
    height: 70px
}

/* Dictates what happen on a mobile device or when screen gets really small */
@media screen and (max-width: 1200px){ 
    .homecontainer{
        opacity: .1;
    }

    .home-flex-container{
        /* display: flex !important;
        flex-direction: column; */
        margin-bottom: 120px !important;
        /* width: fit-content; */
    }

    .home_FeatureTextBlock{
        order: 1;
        margin-bottom: 30px;
    }

    .FeatureImage{
        order: 2;
        width: 100vw;
        max-width: 400px;
    }
    .HomeFeatureTitle{
        /* text-align: center !important; */
    }
}

.Home_ContactUsForm{
    width: 100vw;
    max-width: 1000px;
    padding: 1em;
}

.Home_ContactUsFormInput{
    resize: none;
    padding-left: 10px;
    font-size: 20px;
    border: none;
    box-shadow: var(--boxShadow);
    /* cursor: pointer; */
}
.Home_ContactUsFormTextField{
    /* resize: none; */
    padding-left: 10px;
    font-size: 20px;
    width: 100%;
    height: 100%;
    border: none;
    background-color: rgb(252, 252, 252);
    border-radius: var(--borderRadius) !important;
    margin-bottom: 15px !important;
    /* box-shadow: var(--boxShadow); */
    /* cursor: pointer; */
}


.Home_ContactUsFormSubmitButton{
    font-size: 25px;
    color: white;
    background-color: #364A5E;
    margin-top: 20px !important;
    border-radius: var(--borderRadius) !important;
    /* position: absolute; */
    /* bottom: 0; */
    width: 100%;
    box-shadow: var(--boxShadow);
    border: none;
}

.Home_ContactUsFormSubmitButton:hover{
    background-color: #364a5ed2;
}

.form{
    align-self: center;
    display: inline-block;
    margin: 0 auto;
    padding: 30px;
}

#successMessage{
    margin-top: 10px;
    font-size: 18px;
}