.text-center {
    display: flex;
    height: 100%;
    min-height: 100px;
    justify-content: center;
    align-content: center;
    text-align: center;
  }
  
  /* Dropzone */
  .dropzone {
    padding: 30px;
    height: 100%;
    width: 100%;
    border: 5px dashed #424c75;
    border-radius: 10px;
    outline: none;
    cursor: pointer;
  }
  .dropzone-hover {
    background-color: #d4dcfa33;
  }
  .dropzone-content {
    align-self: center;
    font-size: 17px;
  }