.entireBar{
    overflow: hidden;
    /* box-shadow: var(--boxShadow); */
    margin-bottom: 0px;
    position: fixed;
    /* display: inline-block; */
    background-color: green;
    top: 0;
    overflow-x: hidden;
    width: 100vw;
    /* min-width: 1300px; */
    /* Gets rid of space below navbar... not sure why its there in the first place */
    margin-bottom: -6px !important;
    z-index: 10;
    transition: background-color 0.3s ease;
    
}

.entireBarTest{
    overflow: hidden;
    /* box-shadow: var(--boxShadow); */
    margin-bottom: 0px;
    position: fixed;
    /* display: inline-block; */
    background-color: rgb(54, 74, 94);
    top: 0;
    overflow-x: hidden;
    width: 100vw;
    /* min-width: 1300px; */
    /* Gets rid of space below navbar... not sure why its there in the first place */
    margin-bottom: -6px !important;
    z-index: 10;
    height: 0px;
    transition: height .3s ease-out;

}

.NavBarContent{
    /* background-color: red; */
}

.NavbarItems {
    font-family: Glac;
    /* background-color: #f7f7f7; */
    height: 65px;
/*     background-color: red; */
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    
}

.navBar_Brand{
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    /* justify-content: center; */
}
.HamburgerIcon {
    /* background-color: red; */
    width: 30px;
}

.navbar-logo{
    font-family: Glac;
    
    white-space: nowrap;
    margin-top: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 25px;
}

.blueText{
  color: #364a5e !important
}

@media only screen and (max-width: 600px) {
    .navBar_Brand {
        margin-left: 10px;
    }
  }

.navbar-clinicalAssistant{
    font-family: Glac;
    
    white-space: nowrap;
    margin-top: 8px;
    margin-left:8px;
    cursor: pointer;
    text-decoration: none;
    font-size: 14px;
    font-weight: 10;
    line-height: 85%;
}

.fa-react{
    margin-left: 0.5rem;
    font-size: 1.6rem;
}

/* Five menu options on top right of nav bar */
.nav-menu{
    font-size: medium;
    display: grid;
    grid-template-columns: repeat(6, auto);
    margin-right: 20px;
    list-style: none;
    text-align: center;
    float: right;
    width: 80%;
    justify-content: end;
    margin-top: .8rem;
}

.nav-links{
    /* color: black; */
    text-decoration: none;
    padding: 0.5rem 1rem;
    cursor: pointer;

}

.nav-links:hover{
    background-color:  #6E7794;
    border-radius: var(--borderRadius);
    transition: all .2s ease-out;
}

