.settingsAreaMain{
   padding: 12px;
}
.settingsAreaContent{
    flex-direction: column;
    width: 100%;
}

.settingsAreaContent > div{
    margin-top: 10px;
}

.settingsAreaContentTop{
    padding: 10px;
    display: flex
}

.left {
    width: 60%;
}

.right {
    width: 30%;
}

.full {
    width:100%;
}

.nursingTopicCardArea {
    display: flex;
    flex-wrap: wrap;
}


.settingsAreaTitle{
    border-bottom: 1px solid black;
    width: 300px;
    height: fit-content;
}

.clinicalTableIcon{
    background-color: transparent;
    width: 30px;
    float: left;
    display: flex;
    align-items: left;
    justify-content: left;
    margin: 0px 20px;
}

.trashCanIcon:hover {
    /* Turns icon red. Since there is no background, it just does it to the icon */
    filter: invert(21%) sepia(100%) saturate(7414%) hue-rotate(359deg) brightness(94%) contrast(117%);
}
.editIcon:hover {
    /* Turns icon green. Same green used on the check mark for the student list */
    filter: invert(28%) sepia(99%) saturate(1318%) hue-rotate(93deg) brightness(90%) contrast(106%);
}
.duplicateIcon:hover {
    /* Turns icon green. Same green used on the check mark for the student list */
    filter: invert(28%) sepia(99%) saturate(1318%) hue-rotate(93deg) brightness(90%) contrast(106%);
}

.editIconClinical {
    font-size: 28px !important;
}