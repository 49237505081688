.addStudentsModalHeader{
    width: 100%;
}

.importStudentsButton{
    float: right;
}

.addStudentMessage{
    margin-bottom: 10px;
    font-style: italic;
    color: grey;
}

.importErrorModalBody{
    margin: auto;
    padding-left: 10px;
    /* background-color: blanchedalmond; */
}

.importUsersErrorMessage{
    margin: auto;
    /* background-color: pink; */
    text-align: center;
}

.importUsersErrorMessageRow{
    /* background-color: rgb(255, 240, 213); */
    padding: 7px;
    /* border: 1px solid black; */
    margin: auto;
    margin-bottom: 10px;
    border-radius: var(--borderRadius);
    box-shadow: var(--boxShadow);
}

.studentFileAddClassInput{
    /* background-color: red; */
}

#firstNameInput, #lastNameInput, #emailInput, #studentIDInput{
    padding-left: 10px;
}

