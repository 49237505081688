.newFileButton{
  /* width: fit-content; */
  margin-left: 20px;
  background-color: #24435f;
  color: white;
  border-radius: var(--borderRadius);
  padding: 3px 10px;
  font-size: 18px;
  border: none;
}

.header{
  border-bottom: 2px solid  rgb(202, 202, 202);
  background-color: transparent;
  padding-bottom: 1rem;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  
}
  #lastModifiedHeader{
    float: right;
  }
  
.DashboardCards{
  display: flex;
  justify-content: space-between;
}

.fileContentSection{
  background-color: var(--colorBackground);
  /* background-color: red; */
  border-radius: var(--borderRadius);
  box-shadow: var(--boxShadow);
  /* margin-top: 15px; */
  width: 100%;
  padding: 2rem; 
}

/* Below section of code is not used anywhere - Should I make a habit of deleting things like this that I find? */
.anotherContentSection{
  width: 30%;
  height: 20rem;
  float: left;
  padding: 2rem;
  max-width: 70%; 
  background-color: var(--colorBackground);
  border-radius: var(--borderRadius);
  box-shadow: var(--boxShadow);
}

.fileRow{
  border-bottom: 2px solid rgb(202, 202, 202);
  padding: 5px;
  width: 100%;
  display: flex;
}

.fileName{
  display: flex;
  background-color: transparent;
  width: 100%;
  text-align: left;
  padding-left: 30px;
  border: none;
  
  /* prevents from overflowing */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display:inline-block;
}
.fileName>div{
  margin-left: 0px;
}

.lastModified{
  background-color: transparent;
  border: none;
  display: inline;
  width: 50%;
  padding-right: 30px;
  text-align: right;
  
}

.noFilesAlert{
  width: 100%;
  padding-top: 15px;
  border: none;
  background-color: transparent;
}

.newFileInput{
  display: block;
  margin: auto;
  border-radius: 3px !important;
  border: 1px solid black;
  background-color: var(--colorBackground);
  width: 75%;
}

.nursingTopicsTip{
  margin: 0px 8px;
  font-size: 14px;
  font-style: italic;
  /* margin-bottom: 5px; */
}

.newFileError{
  padding: 4px;
  background-color: red;
  border-radius: var(--borderRadius);
  color: white;
}