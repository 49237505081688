html {
    --boxShadow: 0 0 3px 1px rgb(214, 214, 214);;
    --colorBackground: white;
    --borderRadius: 2px;
  }
  
.random{
    background-color: var(--colorBackground);
    border-radius: var(--borderRadius);
    box-shadow: var(--boxShadow);
}

.buttonTrue{
  background-color: rgb(67, 192, 67);
  border-radius: var(--borderRadius);
  box-shadow: var(--boxShadow);
  /* margin-left: 4.5px; */
  height: 25px;
  width: 75%;
  max-width:170px;
  align-content: center;
  margin: auto;
  border: none;
}

.buttonFalse{
  background-color: rgb(221, 89, 89);
  border-radius: var(--borderRadius);
  box-shadow: var(--boxShadow);
  /* margin-left: 4.5px; */
  height: 25px;
  width: 75%;
  max-width:170px;
  border: none;
}