.navHeader{
    background-color: rgb(255, 255, 255);
    /* background-color: thistle; */
    padding: 9px;
    width: 100%;
    margin-left: 275px;
    height: 72px;
    position: fixed;
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    box-shadow: 0 0 20px rgba(100, 100, 100, 0.2);
    z-index: 1;
}

.rightContainer {
    position: fixed;
    right:0;
    width: fit-content;
    display: flex;
    align-items: center;
}

.iconButton{
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  cursor: pointer;
}

.iconFont {
    color: #717172;
    font-size: 24px;
  }

.layout{
    /* height: 100%; */
    /* background-color: #f0f2f5; */
}

.mainContent{
    /* position: fixed; */
    /* background-color: red; */
    margin-top: 72.5px; /* Other margins are set dynamically based on collapsed state*/
    padding: 0px 10px 10px 10px;
    z-index: 0;
    background-color: var(--applicationNavMainContentBackground);
    min-height: calc(100vh - 72.5px);
}

.sider {
    height: 100%;  
    width: 275px;
    position: fixed;
    z-index: 10;
    
}

.brand{
    z-index: 2;
    position: relative;
    box-shadow: 0 0 20px rgba(100, 100, 100, 0.2) !important;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    background-color: #ffffff;
    cursor: pointer;
    /* background-color: red; */
}

.logo {
    background-color: transparent;
    width: 60px;
    margin: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logoWithName {
    background-color: transparent;
    width: 220px;
    margin: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.siteName{
    font-family: Glac;
    font-weight:900;
    color: rgb(54, 74, 94);
    float: right;
    font-size: 35px;
    margin: 16px 40px 16px 0px
}

.menu{
    position: relative;
    padding-top: 15px;
    flex: 1;
    z-index: 0;
    height: 100%;
    /* background-color: #ffffff; */
    /* background-color: rgb(255, 220, 220); */
    overflow-x: hidden;
    box-shadow: 0 0 20px rgba(100, 100, 100, 0.2);
}



.menuItemIcon{
    vertical-align: middle;
    text-align: center;
    margin-left: 2px;
    font-size: 20px;
}

.ant-menu.ant-menu-light .ant-menu-item-selected {
    background-color: rgb(71, 79, 122); /*Overriden property*/
    color: white;
    /* color */
}

/* This was a work around for the clinical sites icon since
it is not an AntD icon. But it works! */
.ant-menu-item-selected>img{
  filter: invert(100%)
}
.ant-menu-item>img:hover{
  filter: invert(37%) sepia(96%) saturate(676%) hue-rotate(177deg) brightness(107%) contrast(113%);
}
.ant-menu-item-selected>img:hover{
  filter: invert(100%)
}

.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}
  
.trigger:hover {
    color: rgb(93, 108, 196);
}

.notificationPopover {
}
      .ant-popover-inner-content {
        padding: 0;
      }
      .ant-popover-arrow {
        display: none;
      }
      .ant-list-item-content {
        flex: 0;
        margin-left: 16px;
      }
    
.notification {
padding: 24px 0;
width: 320px;
}
.notificationItem {
transition: all 0.3s;
padding: 12px 24px;
cursor: pointer;
}
.notificationItem:hover {
background-color: blue;
}