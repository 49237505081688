.fileUploaderButtonArea {
    display: flex;
}

.fileUploadButton {
    border-radius: 5px !important;
    background-color: rgb(223, 223, 223);
    margin-right: 5px;
    border: none !important;
}

.fileRemoveButton {
    background-color: red !important;
}

.fileUploadName{
    flex-direction: column;
    /* position: relative; */
    font-size: 12px;
}