@font-face {
  font-family: Simp; 
  src: local('Simplifica Regular'), local('Simplifica'); 
  /* Check how the font is written on the machine --> Right click on file -> Details -> Title  */
  src: url(/src/fonts/Simplifica400.ttf) format('truetype'); 
  font-weight: normal;}
@font-face {
    font-family: Glac; 
    /* Check how the font is written on the machine --> Right click on file -> Details -> Title  */
    src: url(/src/fonts/GlacialIndifference-Regular.otf) format('truetype'); 
    src: url(/src/fonts/GlacialIndifference-Regular.otf); 
    font-weight: normal;}

@font-face {
  font-family: Raleway; 
  /* Check how the font is written on the machine --> Right click on file -> Details -> Title  */
  src: url(/src/fonts/Raleway-VariableFont_wght.ttf) format('truetype'); 
  src: url(/src/fonts/Raleway-VariableFont_wght.ttf); 
  font-weight: normal;}

.App_MobileMessage{
  text-align: center;
  border-radius: 10px;  
  text-align: center;
  padding: 10px;
  margin: 15px;
  /* width: fit-content;  */
  background-color: #ffffff; 
  /* max-width: min-content; */
  box-shadow:var(--boxShadow);
}

.underline{
  text-decoration: underline;
}

.App{
  font-family: sans-serif;
  /* font-size: 5px; */
  /* Disable this to make app side scrolling */
  overflow: hidden;
  /* height: 100%; */
  /* for when you zoom in and scroll all the way down */
}

html {
  --boxShadow: 0 0 4px 2px rgb(214, 214, 214);
  --boxShadowWhiteBackground: 0px 0px 4px -1.5px rgba(0, 0, 0, 0.38);
  /* --boxShadow: 0 1px 2px rgba(0,0,0,0.07), 
              0 2px 4px rgba(0,0,0,0.07), 
              0 4px 8px rgba(0,0,0,0.07), 
              0 8px 16px rgba(0,0,0,0.07),
              0 16px 32px rgba(0,0,0,0.07), 
              0 32px 64px rgba(0,0,0,0.07); */
  --colorBackground: white;
  --borderRadius: 10px !important;
  --applicationNavMainContentBackground: rgb(247, 247, 247);

  /******* Have to apply these settings manually to Material Tables *******/
}

/* Scroll Bars */
::-webkit-scrollbar{
  width: 10px;
  background-color: rgba(255, 255, 255, 0.479);
}

::-webkit-scrollbar-thumb{
  border-radius: 3px;
  background-color: #b3b3b3d8;
  box-shadow: inset 0 0 6px rgba(0,0,0,.3);
}

Button{
  border-radius: var(--borderRadius) !important;
}

.center{
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.SecondaryText{
  color: rgba(0, 0, 0, 0.61);
}

legend {
  float: unset;
}