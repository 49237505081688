.scheduleGrid {
  display: grid;
  /* min-height: 90vh;
  max-height: 90vh; */
  grid-template-columns: .9fr 1fr 1fr 1fr 1fr;
  /* grid-template-rows: 43px 40px 315px; */
  grid-template-rows: 43px 40px 45vh;
  grid-template-areas: 
  "functionBar functionBar functionBar functionBar functionBar"
  "studentList studentInfo studentInfo studentInfo studentInfo"
  "studentList studentSchedules studentSchedules studentSchedules studentSchedules"
  "filterSection clinicalSection clinicalSection clinicalSection clinicalSection";
  /* padding: 12px; */
  grid-gap: 12px;
}

.settingsGrid{
  display: grid;
  /* min-height: 90vh;
  max-height: 90vh; */
  grid-template-columns: .9fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 43px 40px 315px;
  grid-template-areas: 
  "functionBar functionBar functionBar functionBar functionBar"
  "settingsSelection settingsArea settingsArea settingsArea settingsArea"
  "settingsSelection settingsArea settingsArea settingsArea settingsArea"
  "settingsSelection settingsArea settingsArea settingsArea settingsArea";
  /* padding: 12px; */
  grid-gap: 12px;
}

.filterSection{
  grid-area: filterSection;
  font-size: 15px;
  /* overflow-y: auto; */
  text-align: center;  
  
  min-height: 33vh;
  /* 1vh (1080p) = 9.4px */
} 

.clinicalSection{
  grid-area: clinicalSection;
  overflow-y: scroll;
  height: max(450px, 50vh);
  /* 1vh (1080p) = 9.4px */

  line-height: 20px;
  display: inline;
  
}
    /* Every other grey line on clinicals table */
    tbody>:nth-child(odd){ 
      background-color: rgb(238, 238, 238);
    }
    .fullClinical{
      color: red
    }
    

.clinicalAssistantMain{
  min-width: 800px;
  /* position: fixed; */
}

.sideBar{
  grid-area: sideBar;
  background-color: var(--colorBackground);
}

.conflict{
  box-shadow: 0 1px 4px 1px red !important ;
}

.functionBarPill{
  color: #f7f7f7;
  padding: 0px 15px;
  font-size: 12px;
  height: 20px;
  line-height:20px;
  margin-top: 1.5px;
  border-radius: var(--borderRadius);
  text-align: center;
  width: 100px;
}

.functionBarPillPublished{
  background-color: #00a921; 
}

.functionBarPillsGray{
  background-color: rgb(163, 163, 163);
}

.functionBarPillClosed{
  background-color: #b60024; 
}

.functionBarPillInProgress{
  background-color: #26425e;  
}

.functionBar{
  grid-area: functionBar;
  /* background-color: red; */
  display: flex;
  width: 100%;
  position: relative;
  /* justify-content: space-between; */
  /* min-width: fit-content; */
}
    #functionBarName{
      /* background-color: teal; */
      /* background-color: rgb(243, 204, 204); */
      height: 100%;
      margin-left: 10px;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      display: flex;
      max-width: 50%;

      /* prevents from overflowing */
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      display:inline-block;
    }
    #functionBarPillsSection{
      height: 100%;
      margin-left: 10px;
      /* padding-left: 10px;
      padding-right: 10px; */
      padding-top: 10px;
      display: flex;
    }
    #functionBarSwitchView{
      font-size: 10px;
    }
    #functionBarButtonsSpan{
      /* background-color: orange; */
      /* min-width: fit-content; */

      margin-right: 12px;
      position: absolute;
      right: 0;
      top: 0;
      /* display: flex; */
      /* justify-content: flex-end; */

      /* min-width: 500px; */
      /* max-width: 500px; */
    }
    #functionBarDropDown{
      /* background-color: teal; */
      background: none;
      border: None;

    }
    .informationIconCA{
      /* background-color: orange; */
      margin-left: 5px;
      margin-right: 5px;
      height: 15px;
      margin-bottom: 14px;
    }
    #showStudentInfoButton{
      /* background-color: green; */
      background-color: transparent;
      border: none;
      height: 25px;

      /* display: inline-block; */
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    #showStudentInfoButton:hover{
      background: #6e7793;
      color: white;
    }
    #showStudentInfoButton:hover > #informationIconCA{
      filter: invert(1);
    }
    
    #showStudentInfoStudentName{
      /* background-color: green; */
      float: left;
      /* background-color: red; */
    }
    #showStudentInfoLockIcon{
      /* background-color: green; */
      margin-top: -5px;
      /* float: right !important; */
    }
    #functionBarButtons{
      color: black;
      /* background-color: green !important; */
      border: none;
      background-color:transparent;
      border-radius: 0px !important;
      vertical-align: text-top;
      margin-top: 2.5px;
      margin-right: 20px;
      padding-top: 5px;
      font-size: 15px;
    }
    #functionBarButtons:hover{
      color: #24435f;
      border-bottom: 1px solid #24435f;
      /* border-radius: var(--borderRadius); */
      /* border-bottom: 1px solid black; */
      /* box-shadow: var(--boxShadow); */
    }
    #functionBarButtons:focus{
      box-shadow: none;
    }
        /* Used to hide real import field */
        input[type='file'] {
          position:absolute;
          opacity: 0;
          z-index: -1;
        }
    #functionBarImportItem{
      background-color: transparent;
      margin-bottom: 5px;
      font-size: 14px;
      text-align: center;
    }
    #functionBarImportItem:hover{
      background-color: rgb(216, 216, 216);
      margin-bottom: 5px;
    }

.ShowStudentNameStudentName{
  /* background-color: red; */
  /* width: 100px; */

  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;    
}
    
.studentList{
  grid-area: studentList;
  overflow: hidden;
  text-align: center;
}

    #studentListTitle{
      font-size: 20px;
      width: 85%;
      text-align: center;
      margin: auto;
      margin-top: 2px;
      /* margin-bottom: 5px; */
      border-bottom: 1px solid black;
      color:#24435f;
      position: sticky;
      z-index: 4;
      background-color: transparent;
      top: 0;
    }
    #studentListItem{
      width: 100%;
      height: auto;
      border: none;
      text-indent: 5%;
      padding-top: 7px;
      padding-bottom: 70px;
    }
    #studentListItem:focus{
      outline: none;
    }
   
.studentInfo{
  /* background-color: red; */
  /* height: fit-content; */
  grid-area: studentInfo;
  overflow: hidden;
  min-width: fit-content;
  /* height: fit-content; */
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding: 8px 10px;
  font-size: 15px;
  /* z-index: 101; */
}
    /* #studentInfoName{
      background-color: red;
      height: 5px;
      margin-top: -10px;
      padding-top: 0px;
      z-index: 100;
    } */
    /* #studentInfoClassYear{
    } */
    #studentInfoNursingTopics{
      min-width: fit-content;
    }
    /* #studentInfoEmail{
    }
    #studentInfoStudentID{
    }
    #studentInfoCircumstances{
    } */
    #circumstanceTrue{
      margin-top: -5%;
      margin-bottom: -5%;
      background-color: rgb(209, 19, 19);
      color: rgb(241, 241, 241);
      border: none;
      font-size: 13px;
      height: 30px;
      vertical-align: middle;
      border-radius: var(--borderRadius);
    }
    #circumstanceFalse{
      /* margin-top: -5%;
      margin-bottom: -5%; */
      /* background-color: gray; */
      color: rgb(243, 243, 243);
      border: none;
      font-size: 13px;
      height: 30px;
      vertical-align: middle;
      border-radius: var(--borderRadius);
    }

.studentSchedulesCompactView{
  display: flex;
  align-content: center;
  overflow: hidden;
  text-align: center;
  height: 100%;
}

.studentSchedulesWeekDayView{
  
}

.studentSchedules{
  grid-area: studentSchedules;
  overflow-x: hidden;
}

    #studentSchedulesBlock{
      width: 100%;
    } 
    #studentSchedulesBlockLAST{
      width: 100%
    }
    #studentSchedulesWeekDayTitle{
      border-bottom: 2px solid black;
      background-color: #24435f;
      color: white;
      font-size: 16px;
      margin: -.000001rem;
    }
    #studentSchedulesClassItem{
      list-style-type: none;
      margin: 5px;
      padding: 3px;
      padding-left: 6px;
      font-size: 11px;
      background-color: #ffffff;
      color: rgb(0, 0, 0);
      border-radius: var(--borderRadius);
      box-shadow: var(--boxShadow);
      text-align: left;
    }
    #studentSchedulesBlockedTimeItem{
      list-style-type: none;
      cursor: pointer;
      margin: 5px;
      padding: 3px;
      padding-left: 6px;
      font-size: 11px;
      background-color: #424244;
      color: rgb(255, 255, 255);
      border-radius: var(--borderRadius);
      box-shadow: var(--boxShadow);
      text-align: left;
    }
    #studentSchedulesClinicalItem{
      border: .08em solid rgb(97, 97, 97);
      list-style-type: none;
      margin: 5px;
      padding: 6px;
      font-size: 11px;
      background-color: #6E7794;
      color: white;
      border-radius: var(--borderRadius);
      box-shadow: var(--boxShadow);
      text-align: center;
      cursor: pointer;
    }

.verticalBar{
  size: 1rem;
}

.filterButtonSection{
  padding-left: 16px;
  padding-right: 16px;
  display: block;
}

  #filterTitle{
    /* background-color: red; */
    padding-top: 15px;
    margin-bottom: 3px;
    margin-top: 0px;
    position: relative;
  }
   #filterWeekdayID{
     width: 85%;
     margin: auto;
     margin-bottom:-20px ;
     font-size: 14px;
     box-shadow: 0 0 8px -2px rgb(214, 214, 214);
   }
    /* #filterSectionWeekDays{
      
    }
    #filterSectionClinicalType{
      
    } */
.filterSectionFullClinicalsButton{
  margin: 13px 0;
  margin-top: 50px;
  width: fit-content;
  padding: 10px;
  border: none;
  /* background-color: gray; */
  font-size: 15px;
  border-radius: var(--borderRadius);
  box-shadow: var(--boxShadow);
}
.filterSectionScheduleForMeButton{
  margin: 0px;
  height: 50px !important;
  margin-bottom: 40px;
  width: 200px;
  min-width: 170px;
  padding: 13px;
  border: none;
  font-size: 17px;
  border-radius: var(--borderRadius);
  box-shadow: var(--boxShadow);
}
    
.messageBox{
  width: fit-content;
  margin: auto;
  
}
 
/* EVERYTHING ELSE NOT IN THE GRID */

section{
  background-color: var(--colorBackground);
  border-radius: var(--borderRadius);
  box-shadow: var(--boxShadow);
  
}

.title{
  margin-top: -.75rem ;
  font-size: 30px;
  grid-area: title;
  min-width: 150px;
  white-space: nowrap;
  
  }

.importFile{
  grid-area: importFile;
  visibility: hidden;
}
  

/* Import File Drop Down */

.dropbtn {
  background-color: #aacef7;
  border-radius: var(--borderRadius);
  margin-right: 10px;
  border: none;
}

.dropdown {
  /* position: relative; */
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  box-shadow: var(--boxShadow);
  /* z-index: -1; */
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 12px;
}

.dropdown-content a:hover {
  background-color: #ddd;
  min-width: fit-content;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {background-color: #53a1fa;}

/* ----- */



.importErrorModalBody{
  /* background-color: rgb(250, 148, 148); */
  height: 300px;
  padding-right: 10px;
  overflow-y: scroll;
  padding-bottom: 10px;
  max-width: 900px;
}
  #importErrorDefaultMsg{
    background-color: #ff5454;
    color: white;
    width: 100%;
    margin: auto;
    margin-bottom: 20px;
    border-radius: var(--borderRadius);
    text-align: center;
    padding: 10px;

  }
  #importErrorRow{
   width: 100%;
   border: 1px solid rgb(0, 0, 0);
   border-radius: var(--borderRadius);
   /* background-color: red; */
   margin-top: 2px;
   padding: 2px;
   
  }
  #row{
    display: inline-block;
    width: 60px;
    text-align: center;
    
    border-right: 1px solid rgb(146, 146, 146);
    /* border: 1px solid rgb(0, 0, 0); */
    
  }
  #column{
    display: inline-block;
    width: 120px;
    text-align: center;
    border-right: 1px solid rgb(146, 146, 146);
  }
  #message{
    display: inline-block;
    width: 680px;
    text-align: center;
  }
  #messageFull{
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  #excessiveErrors{
    background-color: #8a8a8a;
    color: white;
    font-size: 14px;
    width: 80%;
    margin: auto;
    margin-bottom: 20px;
    border-radius: var(--borderRadius);
    text-align: center;
    padding: 10px;
  }

.circumstanceHover{
  max-width: 45%;
  background-color: rgba(78, 78, 78, 0.932);
  color: white;
  /* margin-top: 60px; */
  margin-right: 1px;
  padding: 10px;
  border-radius: var(--borderRadius);
  width: fit-content;
} 

.clinicalInfoContainer{
  display:flex;
  flex-direction: row;
}

#clinicalInfoLeftSide{
  box-sizing: border-box;
  list-style-type: none;
  float: left;
  width: 50%;
  min-height: 300px;
}
#clinicalInfoRightSide{
  box-sizing: border-box;
  list-style-type: none;
  float: right;
  width: 50%;
  min-height: 300px;
}

.enrolledStudentsHover{
  color: white;
}

#enrolledStudents{
  box-sizing: border-box;
  
  background-color: #24435f;
  color: rgb(243, 243, 243);
  border: none;
  font-size: 15px;
  
  margin-left: auto;
}


/* ----- Edit Window ----- */

.editWindowMain{
  margin-top: 62px;
  /* height: 100vh; */
}
.fileNameEditWindow{
   margin-left: 20px;
   width: fit-content;
 }
.myLabelClass{
  margin-left: 20px;
  width: fit-content;
}
#editStudentListItem{
  min-width: 250px;
  border: 1px solid black;
  float: left;
  /* height: auto; */
  border: none;
  text-indent: 5%;
  padding-top: 7px;
  padding-bottom: 70px;
}
#editStudentListItem:focus{
  outline: none;
}
.editLabel{
  font-size: 20px;
}
.editStudentInfo{
  padding: 20px;
  background-color: var(--colorBackground);
  display: inline-block;
  min-width: 300px;
}
.studentsEditWindow{
  padding: 10px;
  max-height: fit-content;
  background-color: rgb(197, 197, 197);
  overflow:auto;
  display: flex;
}
#editClinicalListItem{
  min-width: 250px;
  border: 1px solid black;
  float: left;
  /* height: auto; */
  border: none;
  text-indent: 5%;
  padding-top: 7px;
  padding-bottom: 70px;
}
.clinicalsEditWindow{
  width: 100%;
  padding: 10px;
  max-height: fit-content;
  background-color: rgb(197, 197, 197);
  overflow:auto;
  display: flex;
}
.editClinicalInfo{
  padding: 20px;
  background-color: var(--colorBackground);
  display: inline-block;
  min-width: 300px;
}

input{
  width: 100%;
}
textarea{
  width: 100%;
}
.fluff{
  /* float: right; */

  background-color: rgb(213, 224, 255);
}

.clinicalAssistantBlockedTimeReason{
  background-color: transparent;
    list-style: none outside none;
    /* margin-top: 20px; */
    position: relative;
    /* width: 100%; */
    height: 285px;
    overflow: auto;
}

.clinicalAssistantBlockedTimeReason:after {
  content: '';
  position: sticky;
  display: block;
  bottom: 0;
  width: 100%;
  height: 55px;
  background-image: linear-gradient(to top, rgba(255, 255, 255, 0.849) 45%, transparent 90%);
}

.functionBarViewButton{
  border-radius: 0 !important;
  border: none;
  margin-top: 7px;
  width: 120px;
  background-color: transparent;
}
.functionBarViewButton:hover{
  background-color: #f7f7f7; 
}

.settingsSelection{
  grid-area: settingsSelection;
  overflow: hidden;
  text-align: center;
}

.settingsArea{
  grid-area: settingsArea;
  /* background-color: red; */
  display: flex;
  justify-content: space-between;
  min-width: fit-content;
}