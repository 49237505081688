.NotFoundPage_Container{
    margin-top: 30px !important;
    margin-bottom: 30px !important;
    padding: 20px !important;
    /* background-color: red !important; */
    width: 800px;
    border-radius: var(--borderRadius);
    box-shadow: var(--boxShadow);
    margin-top: 100px !important;
    
    /* color: red; */
    /* width: 10px; */
}

.NotFoundPage_Text{
    text-align: left;
    margin-top: 20px;
    margin-left: 20px;
}

.NotFoundPageButton{
    margin-top: 5px !important;
    padding: 5px 50px;
    background-color: #24435f;
    color: white;
    border: none;
    margin-bottom: 10px !important;
}