.dashboardCardNumberCard {
    /* padding-left: 30px; */
    padding: 20px 20px 25px 20px;
    /* max-width: 200px; 
    /* height: auto; */
    width: 100%;
    background-color: var(--colorBackground);
    border-radius: var(--borderRadius) !important;
    box-shadow: var(--boxShadow);
    /* background-color: red; */
}

.dashboardCardContainer{
    padding-left: 20px;
}

.dashboardCardNumberCard:not(:last-child){
    margin: 0 20px 20px 0;
}
.dashboardCardNumberCard:last-child{
    margin: 0 0 20px 0;
}

.iconWarp {
    height: 90%;
    position: relative;
    padding-left: 15px;
    /* background-color: teal; */
}

.dashboardCardIcon {
    /* background-color: orange; */
    
    width: 60px;
    /* float: left !important; */
    font-size: 45px;
    line-height: 1px;
    /* margin: 0; */
    /* position: absolute; */
    /* top: 50%; */
    /* -ms-transform: translateY(-50%); */
    /* transform: translateY(-50%); */
  }

.content {
    display: flex;
    /* background-color: rebeccapurple; */
    /* width: 100px; */
    /* padding-left: 90px; */
}

.dashboardCardTitle {
    /* background-color: red; */
    /* text-align: center; */
    /* background-color: red; */
    font-size: 25px;
    /* top:0%; */
    /* box-sizing: border-box; */
    width: 100%;
}

.dashboardCardContainer{
    
}

.dashboardCardNumber {
    /* background-color: orchid; */
    width: 100px;
    /* float: right; */
    /* line-height: 32px; */
    font-size: 30px;
    /* height: 32px; */
    /* text-align: left; */
    margin-bottom: 0;
}